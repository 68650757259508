$(document).off('click', '#register_button');
$(document).on('click', '#register_button', () => {
    companyIsValid((company_is_valid) => {
        userIsValid((user_is_valid) => {
            if (company_is_valid && user_is_valid ) {
                let form = $('#registration_form');
                $.ajax({
                    url: form.attr('action'),
                    type: form.attr('method'),
                    dataType: 'json',
                    data: form.serialize(),
                    success: function (server_return) {
                        if (server_return.status === 'user_failed') {
                            swal("Error", "The account details you have entered have failed to save, please try again.", "error");
                        } else if (server_return.status === 'company_failed') {
                            swal("Error", "The company details you have entered have failed to save, please try again.", "error");
                        } else if (server_return.status === 'success') {
                            window.location.href = '/';
                        }
                    },
                    error: function (xhr, err) {
                        swal("Error", "The server is unable to process the request, please try again later.", "error");
                    }
                });
            }
        });
    });
});

function companyIsValid(callback) {
    let is_valid = true;
    let company_name = $('#company_name_input');
    let cipc_registration_number = $('#cipc_registration_input');
    let address = $('#address_input');

    if (company_name.val() === '') {
        company_name.addClass('error');
        is_valid = false;
    } else
        company_name.removeClass('error');

    if (cipc_registration_number.val() === '') {
        cipc_registration_number.addClass('error');
        is_valid = false;
    } else
        cipc_registration_number.removeClass('error');

    if (address.val() === '') {
        address.addClass('error');
        is_valid = false;
    } else
        address.removeClass('error');

    if (is_valid) {
        $.post('/validate_company_name', {name: company_name.val()}, (data) => {
            if (data.valid)
                company_name.removeClass('error');
            else
                company_name.addClass('error');
            callback(data.valid);
        });
    } else {
        callback(is_valid);
    }

}

function userIsValid(callback) {
    let is_valid = true;
    let first_name = $('#first_name_input');
    let last_name = $('#last_name_input');
    let email_address = $('#email_address_input');
    let mobile_number = $('#mobile_number_input');
    let password = $('#password_input');
    let password_confirm = $('#password_confirm_input');

    if (first_name.val() === '') {
        first_name.addClass('error');
        is_valid = false;
    } else
        first_name.removeClass('error');

    if (last_name.val() === '') {
        last_name.addClass('error');
        is_valid = false;
    } else
        last_name.removeClass('error');

    if (email_address.val() === '') {
        email_address.addClass('error');
        is_valid = false;
    } else
        email_address.removeClass('error');

    if (mobile_number.val() === '' || mobile_number.val().length !== 10) {
        mobile_number.addClass('error');
        is_valid = false;
    } else
        mobile_number.removeClass('error');

    if (password.val() === '') {
        password.addClass('error');
        is_valid = false;
    } else {
        if (password.val() !== password_confirm.val()) {
            password.addClass('error');
            password_confirm.addClass('error');
        } else {
            password.removeClass('error');
            password_confirm.removeClass('error');
        }
    }

    if (is_valid) {
        $.post('/validate_email_address', {email: email_address.val()}, (data) => {
            if (data.valid) {
                email_address.removeClass('error');
                $.post('/validate_mobile_number', {number: mobile_number.val()}, (m_data) => {
                    if (m_data.valid) {
                        mobile_number.removeClass('error');
                        callback(m_data.valid);
                    } else {
                        mobile_number.addClass('error');
                    }
                })
            } else
                email_address.addClass('error');
        });
    } else {
        callback(is_valid);
    }
}